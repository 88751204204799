.subscribe-container {
	display: flex;
	flex-direction: column;
	height: 300px;
	align-items: center;
	justify-content: center;
	padding-top: 40px;
}

.subscribe-form {
	text-align: center;
}

.subscribe-text {
	margin: 5px;
}

.subscribe-input {
	margin-bottom: 20px;
	outline: none;
	border: none;
	border: 1px solid #fff;
	border-radius: 2px;
	font-size: 40px;
	text-align: center;
	width: 500px;
	padding-bottom: 0px;
	text-align: center;
}

.subscribe-input::placeholder {
	color: #b1b1b1;
	text-align: center;
}

.subscribe-button-container {
	display: flex;
	height: 80px;
	justify-content: center;
	align-items: center;
	width: 500px;
}

.subscribe-button {
	border: 1px solid white;
	color: white;
	font-size: 28px;
	width: 100%;
}

@media screen and (max-width: 960px) {
	.subscribe-input {
		width: 450px;
	}
}

@media screen and (max-width: 960px) {
	.subscribe-button-container {
		width: 450px;
	}
}

@media screen and (max-width: 760px) {
	.subscribe-input {
		width: 350px;
	}
}

@media screen and (max-width: 760px) {
	.subscribe-button-container {
		width: 350px;
	}
}
