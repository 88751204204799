h1 {
	color: rgb(81, 152, 206);
	font-size: 3rem;
	padding-top: 75px;
	text-align: center;
}

h3 {
	color: rgb(81, 152, 206);
	font-size: 2.2rem;
	padding: 25px 0 25px 0;
	text-align: center;
}

.main-primary-section-container {
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	height: 95vh;
	object-fit: contain;
	width: 100%;
}

.main-primary-background-image {
	-webkit-filter: blur(4px);
	filter: blur(4px);
	height: 100%;
	object-fit: cover;
	position: fixed;
	width: 100%;
	z-index: -1;
}

.main-primary-background-image-animated {
	-moz-animation: zoom-in-zoom-out 40s ease infinite;
	-o-animation: zoom-in-zoom-out 40s ease infinite;
	-webkit-animation: zoom-in-zoom-out 40s ease infinite;
	animation: zoom-in-zoom-out 40s ease infinite;
	height: 100%;
	object-fit: cover;
	position: fixed;
	width: 100%;
	z-index: -1;
}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.75, 1.75);
	}
	100% {
		transform: scale(1, 1);
	}
}

@media screen and (max-width: 960px) {
	.main-section-container > h1 {
		color: rgb(81, 152, 206);
		font-size: 80px;
		margin-top: 40px;
	}
}

@media screen and (max-width: 960px) {
	.main-section-container > h3 {
		color: rgb(81, 152, 206);
		font-size: 50px;
		margin-top: 20px;
	}
}

@media screen and (max-width: 760px) {
	.main-section-container > h1 {
		color: rgb(81, 152, 206);
		font-size: 70px;
		margin-top: 30px;
	}
}

@media screen and (max-width: 760px) {
	.main-section-container > h1 {
		color: rgb(81, 152, 206);
		font-size: 30px;
		margin-top: 10px;
	}
}
