:root {
	--primary: rgb(81, 152, 206);
}

.btn {
	border-radius: 2px;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 8px 20px;
}

.btn--primary {
	background-color: var(--primary);
	border: 1px solid var(--primary);
	color: rgb(81, 152, 206);
}

.btn--outline {
	background-color: transparent;
	border: 1px solid var(--primary);
	color: rgb(81, 152, 206);
	font-size: 1.3rem;
	padding: 8px 20px;
	transition: all 0.3s ease-out;
}

.btn--medium {
	font-size: 20px;
	padding: 8px 20px;
}

.btn--large {
	font-size: 20px;
	padding: 12px 26px;
}

.btn--medium:hover,
.btn--large:hover {
	background: rgb(81, 152, 206);
	color: rgb(81, 152, 206);
	transition: all 0.3s ease-out;
}
