.navbar {
	align-items: center;
	background: #fff;
	display: flex;
	font-size: 1.3rem;
	height: 80px;
	justify-content: center;
	position: sticky;
	top: 0;
	z-index: 999;
}

.navbar-container {
	align-items: center;
	display: flex;
	height: 80px;
	justify-content: center;
	max-width: 1500px;
}

.navbar-logo {
	align-items: center;
	color: rgb(81, 152, 206);
	cursor: pointer;
	display: flex;
	font-size: 2rem;
	justify-self: start;
	margin-left: 20px;
	text-decoration: none;
}

.nav-menu {
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(4, auto);
	justify-content: end;
	list-style: none;
	margin-right: 2rem;
	text-align: center;
	width: 60vw;
}

.nav-item {
	height: 70px;
}

.nav-links {
	align-items: center;
	color: rgb(81, 152, 206);
	display: flex;
	height: 100%;
	padding: 0.5rem 1rem;
	text-decoration: none;
}

.nav-links:hover {
	border-bottom: 4px solid rgb(81, 152, 206);
	transition: all 0.2s ease-out;
}

.fa-bars {
	color: rgb(81, 152, 206);
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media screen and (max-width: 960px) {
	.nav-menu {
		display: flex;
		flex-direction: column;
		height: 95vh;
		left: -100%;
		opacity: 1;
		position: absolute;
		top: 80px;
		transition: all 0.5s ease;
		width: 100%;
	}

	.nav-menu.active {
		background: #fff;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.nav-links {
		display: table;
		font-size: 1.6rem;
		margin-top: 45px;
		padding: 2rem;
		text-align: center;
		width: 100%;
	}

	.nav-links:hover {
		background-color: rgb(77, 147, 201);
		border-radius: 0;
		color: #fff;
	}

	.navbar-logo {
		left: 0;
		position: absolute;
		top: 0;
		transform: translate(25%, 50%);
	}

	.menu-icon {
		cursor: pointer;
		display: block;
		font-size: 1.8rem;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-100%, 60%);
	}

	.fa-times {
		color: rgb(77, 147, 201);
		font-size: 2rem;
	}

	.nav-links-mobile {
		background-color: transparent;
		border-radius: 4px;
		border: 1px solid rgb(77, 147, 201);
		color: rgb(77, 147, 201);
		display: block;
		font-size: 1.6rem;
		margin: 7rem auto;
		padding: 15px 15px;
		text-align: center;
		text-decoration: none;
		transition: all 0.3s ease-out;
		width: 80%;
	}

	.nav-links-mobile:hover {
		background: rgb(77, 147, 201);
		color: #fff;
		transition: 400ms;
	}
}
