.footer-container {
	align-items: center;
	background-color: #fff;
	border: 1px solid rgb(81, 152, 206);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 3rem 0 1rem 0;
}

.footer-subscription {
	align-items: center;
	color: rgb(81, 152, 206);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 12px;
	padding: 12px;
	text-align: center;
}

.footer-subscription-heading {
	font-size: 30px;
	margin-bottom: 12px;
}

.footer-input {
	border-radius: 2px;
	border: 1px solid rgb(81, 152, 206);
	font-size: 18px;
	margin-bottom: 5px;
	margin-right: 10px;
	outline: none;
	padding: 8px 20px;
	text-align: center;
}

.footer-email-form h2 {
	margin-bottom: 2rem;
}

.footer-input::placeholder {
	color: rgb(81, 152, 206);
	text-align: center;
}

.content {
	max-width: 1000px;
	width: 100%;
}

.social-media-wrap {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 40px auto 0 auto;
	max-width: 1000px;
	width: 90%;
}

.social-icons {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 350px;
}

.social-icon-link {
	color: rgb(81, 152, 206);
	font-size: 24px;
	text-decoration: none;
}

.copyright-wrap {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 40px auto -10px auto;
	max-width: 1000px;
	width: 90%;
}

.privacy-policy-wrap {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0 auto 0 auto;
	max-width: 1000px;
	width: 90%;
}

.website-rights {
	align-items: center;
	color: rgb(81, 152, 206);
	display: flex;
	font-size: 1.2rem;
	justify-self: start;
	margin-bottom: 16px;
	padding: 5px;
	text-decoration: none;
}

.privacy-policy-wrap > .website-rights:hover {
	text-decoration: underline;
}

@media screen and (max-width: 820px) {
	.footer-input {
		width: 100%;
	}

	.social-media-wrap .copyright-wrap .privacy-policy-wrap {
		flex-direction: column;
	}
}
