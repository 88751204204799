.cards {
	background: #fff;
	display: inline-block;
	padding: 2rem;
	object-fit: contain;
	width: 100%;
}

.cards__container {
	align-items: center;
	display: flex;
	flex-flow: column;
	margin: 0 auto;
	max-width: 1120px;
	object-fit: contain;
	width: 100%;
}

.cards__wrapper {
	margin: 50px 0 25px;
	position: relative;
}

.cards__items {
	margin-bottom: 25px;
}

.cards__item {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.cards__item__img__box {
	align-items: center;
	display: flex;
	flex-flow: row;
	justify-content: center;
}

.cards__item__img {
	align-items: center;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
	display: flex;
	justify-content: center;
	max-width: 90%;
	width: 90%;
}

.cards__item__text__box {
	align-items: center;
	display: flex;
	flex-flow: column;
	height: 75%;
	justify-content: center;
	margin: auto;
	width: 75%;
}

.cards__item__text {
	align-items: center;
	display: flex;
	justify-content: center;
}

@media only screen and (min-width: 1024px) {
	.cards__items {
		display: flex;
	}
}

@media only screen and (max-width: 1024px) {
	.cards__item {
		margin-bottom: 2rem;
	}
}
